import React, { useEffect } from 'react';
import logger from '@helpers/utils/logger/client';
import { useAuthentication } from '@context/authentication.context';
import extractFromContext from '@helpers/utils/extract-from-context';
import { serverSideTranslations } from 'next-i18next/serverSideTranslations';
import { GetServerSideProps, NextPageWithLayout } from 'next';
import DefaultLayout from '@layouts/default-layout';
import useUser from '@hooks/user/use-user';
import { getAppInitialProps } from '@helpers/initial-props';

const LoginPage: NextPageWithLayout = () => {
  const { isAuthenticated, logout } = useUser();
  const auth = useAuthentication();
  const { setAuthOpen, setRedirectUrl, setOnAuth } = auth;

  // Function to validate and sanitize the redirect URL
  function validateAndSanitizeRedirectUrl(redirectUrl) {
    const allowedDomains = ['vestiairecollective.com', 'localhost'];
    try {
      const url = new URL(redirectUrl, window.location.origin);
      const redirectDomain = url.hostname.split('.').slice(-2).join('.');
      if (allowedDomains.includes(redirectDomain)) {
        return url.href;
      }
    } catch (err) {
      logger.error(err, 'Invalid redirect URL');
    }
    return '/';
  }

  useEffect(() => {
    const urlParams = new URLSearchParams(window.location.search);

    const redirectParam = urlParams.get('redirect');
    const nextUrl = validateAndSanitizeRedirectUrl(redirectParam ?? '/');
    let userHasBeenLogedOut = false;

    // If the redirect is my-account.shtml we will log the user out to avoid an infinite redirect loop
    // this happens because monolith auth checks are different from fenx.
    if (nextUrl.includes('my-account.shtml')) {
      logout();
      userHasBeenLogedOut = true;
    }

    setOnAuth(() => () => {
      window.location.replace(nextUrl);
    });

    if (isAuthenticated && !userHasBeenLogedOut) {
      // if already authenticated don't show the modal
      window.location.replace(nextUrl);
      return;
    }

    setRedirectUrl({ url: '/', mode: 'push' });
    setAuthOpen(true);

    // eslint-disable-next-line react-hooks/exhaustive-deps -- never re-execute
  }, []);

  return <div />;
};

LoginPage.Layout = [DefaultLayout];
LoginPage.PageType = 'LoginPage';

export const getServerSideProps: GetServerSideProps = async (context) => {
  context.res.setHeader('x-vc-page', 'staticPage');

  const { locale } = extractFromContext(context);
  const initialProps = await getAppInitialProps(context);
  const translations = await serverSideTranslations(locale, ['default-layout', 'common']);

  return {
    props: {
      ...initialProps,
      ...translations,
    },
  };
};

export default LoginPage;
